var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "fill-height primary", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "", align: "center", justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "8", md: "6", lg: "4" } },
            [
              _c(
                "v-form",
                { attrs: { autocomplete: "on" }, on: { submit: _vm.register } },
                [
                  _c(
                    "v-card",
                    { staticClass: "elevation-5 pa-3" },
                    [
                      _c(
                        "div",
                        { staticClass: "text-right" },
                        [_c("localization")],
                        1
                      ),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-snackbar",
                            {
                              attrs: {
                                timeout: 30000,
                                top: "",
                                color: _vm.color,
                                tile: "",
                              },
                              model: {
                                value: _vm.showResult,
                                callback: function ($$v) {
                                  _vm.showResult = $$v
                                },
                                expression: "showResult",
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.result) + " ")]
                          ),
                          _c(
                            "div",
                            { staticClass: "layout column align-center" },
                            [
                              _c("img", {
                                attrs: {
                                  src: "img/icons/logo.png",
                                  alt: "Logo Vitissia",
                                  width: "241",
                                  height: "115",
                                },
                              }),
                              _c(
                                "h1",
                                {
                                  staticClass: "text-center my-4 primary--text",
                                },
                                [_vm._v(" Inscription ")]
                              ),
                            ]
                          ),
                          _c("v-text-field", {
                            attrs: {
                              name: "name",
                              label: _vm.$t("login.name"),
                              required: "",
                              rules: [_vm.rules.required],
                              autocomplete: "username",
                            },
                            model: {
                              value: _vm.model.nom,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "nom", $$v)
                              },
                              expression: "model.nom",
                            },
                          }),
                          _c("v-text-field", {
                            attrs: {
                              name: "firstname",
                              label: _vm.$t("login.firstname"),
                              rules: [_vm.rules.required],
                              required: "",
                              autocomplete: "username",
                            },
                            model: {
                              value: _vm.model.firstname,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "firstname", $$v)
                              },
                              expression: "model.firstname",
                            },
                          }),
                          _c("v-text-field", {
                            attrs: {
                              "append-icon": "mdi-at",
                              name: "email",
                              rules: [_vm.rules.required, _vm.rules.email],
                              label: _vm.$t("login.email"),
                              type: "email",
                              required: "",
                              autocomplete: "username",
                            },
                            model: {
                              value: _vm.model.email,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "email", $$v)
                              },
                              expression: "model.email",
                            },
                          }),
                          _c("vue-tel-input-vuetify", {
                            attrs: {
                              mode: "international",
                              placeholder: "",
                              rules: [_vm.rules.required],
                              label: "Téléphone",
                              "preferred-countries": ["fr", "gb"],
                            },
                            model: {
                              value: _vm.model.phone,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "phone", $$v)
                              },
                              expression: "model.phone",
                            },
                          }),
                          _c("v-text-field", {
                            attrs: {
                              "append-icon": _vm.show1
                                ? "mdi-eye"
                                : "mdi-eye-off",
                              name: "password",
                              label: _vm.$t("login.password"),
                              rules: [_vm.rules.required],
                              type: _vm.show1 ? "text" : "password",
                              required: "",
                              autocomplete: "new-password",
                            },
                            on: {
                              "click:append": function ($event) {
                                _vm.show1 = !_vm.show1
                              },
                            },
                            model: {
                              value: _vm.model.password,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "password", $$v)
                              },
                              expression: "model.password",
                            },
                          }),
                          _c("v-text-field", {
                            attrs: {
                              "append-icon": _vm.show2
                                ? "mdi-eye"
                                : "mdi-eye-off",
                              name: "confirm",
                              rules: _vm.confirmPasswordRules.concat(
                                _vm.passwordConfirmationRule
                              ),
                              label: _vm.$t("login.confirm"),
                              type: _vm.show2 ? "text" : "password",
                              required: "",
                              autocomplete: "new-password",
                            },
                            on: {
                              "click:append": function ($event) {
                                _vm.show2 = !_vm.show2
                              },
                            },
                            model: {
                              value: _vm.model.confirmPassword,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "confirmPassword", $$v)
                              },
                              expression: "model.confirmPassword",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        type: "submit",
                                        color: "primary",
                                        loading: _vm.loading,
                                        block: "",
                                      },
                                    },
                                    [_vm._v(" Valider ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "primary",
                                        text: "",
                                        to: "/signin",
                                        block: "",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("login.signIn")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "primary",
                                        text: "",
                                        to: "/forgottenPassword",
                                        block: "",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("login.forgottenPassword")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }