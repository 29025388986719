<template>
    <v-container fluid class="fill-height primary">
        <v-row no-gutters align="center" justify="center">
            <v-col cols="12" sm="8" md="6" lg="4">
                <v-form autocomplete="on" @submit="register">
                    <v-card class="elevation-5 pa-3">
                        <div class="text-right">
                            <localization />
                        </div>
                        <v-card-text>
                            <v-snackbar v-model="showResult" :timeout="30000" top :color="color" tile>
                                {{ result }}
                            </v-snackbar>
                            <div class="layout column align-center">
                                <img src="img/icons/logo.png" alt="Logo Vitissia" width="241" height="115">
                                <h1 class="text-center my-4 primary--text">
                                    Inscription
                                </h1>
                            </div>
                            <v-text-field v-model="model.nom" name="name" :label="$t('login.name')" required
                                :rules="[rules.required]" autocomplete="username" />
                            <v-text-field v-model="model.firstname" name="firstname" :label="$t('login.firstname')"
                                :rules="[rules.required]" required autocomplete="username" />
                            <v-text-field v-model="model.email" append-icon="mdi-at" name="email"
                                :rules="[rules.required, rules.email]" :label="$t('login.email')" type="email" required
                                autocomplete="username" />
                            <vue-tel-input-vuetify v-model="model.phone" mode="international" placeholder=""
                                :rules="[rules.required]" label="Téléphone" :preferred-countries="['fr', 'gb']" />
                            <v-text-field v-model="model.password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                name="password" :label="$t('login.password')" :rules="[rules.required]"
                                :type="show1 ? 'text' : 'password'" required autocomplete="new-password"
                                @click:append="show1 = !show1" />
                            <v-text-field v-model="model.confirmPassword" :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                                name="confirm" :rules="confirmPasswordRules.concat(passwordConfirmationRule)"
                                :label="$t('login.confirm')" :type="show2 ? 'text' : 'password'" required
                                autocomplete="new-password" @click:append="(show2 = !show2)" />
                        </v-card-text>
                        <v-card-actions>
                            <v-row>
                                <v-col cols="12">
                                    <v-btn type="submit" color="primary" :loading="loading" block>
                                        Valider
                                    </v-btn>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn color="primary" text to="/signin" block>
                                        {{ $t('login.signIn') }}
                                    </v-btn>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn color="primary" text to="/forgottenPassword" block>
                                        {{ $t('login.forgottenPassword') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Localization from '../widget/AppLocalization.vue';

export default {
    name: 'SignUp',
    components: { Localization },
    data: () => ({
        loading: false,
        showResult: false,
        result: '',
        color: 'undefined',
        show1: false,
        show2: false,
        model: {
            email: '',
            password: '',
            confirmPassword: '',
            firstname: '',
            nom: '',
            phone: ''
        },
        confirmPasswordRules: [(v) => !!v || ''],
        rules: {
            required: (value) => !!value || 'Obligatoire.',
            email: (value) => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return pattern.test(value) || 'Email invalide.';
            },
        },
    }),
    computed: {
        passwordConfirmationRule() {
            return () => this.model.password === this.model.confirmPassword || 'Les mots de passe doivent correspondre';
        }
    },
    methods: {
        async register(e) {
            e.preventDefault();
            const vm = this;
            if (this.model.email === '' || this.model.password === '' || this.model.nom === '') {
                this.showResult = true;
                this.color = 'red';
                this.result = 'Veuillez renseigner les champs nécessaires';
                return;
            }
            const url = `${this.$config.API_ENDPOINT}/4DACTION/ksf_sys_web_registeruser`;
            const bodyFormData = new FormData();
            bodyFormData.append('nom_register', this.model.nom);
            bodyFormData.append('prenom_register', this.model.firstname);
            bodyFormData.append('email_register', this.model.email);
            bodyFormData.append('mdp_register', this.model.password);
            bodyFormData.append('phone', this.model.phone);
            const response = await this.$axios.post(url, bodyFormData);
            const result = response.data;
            if (result.header === 'Succès') {
                const urlimageuser = `${vm.$config.API_ENDPOINT}/4DACTION/ksf_sys_web_Recu_image_User/${response.data.user.UUID}`;
                const user = {
                    email: response.data.user.email,
                    password: response.data.user.password,
                    phone: response.data.user.phone,
                    user: `${response.data.user.nom} ${response.data.user.prenom}`,
                    status: 'admin',
                    code: '',
                    token: response.data.user.UUID,
                    name: `${response.data.user.nom}`,
                    firstname: `${response.data.user.prenom}`,
                    avatar: urlimageuser,
                    roles: ['admin'],
                };

                vm.result = 'Votre compte a bien été créé !';
                vm.color = 'green';
                vm.showResult = true;
                setTimeout(() => {
                    vm.$router.push({ path: '/registercompany', query: { uuid: user.token } });
                }, 5000);
            } else {
                vm.error = true;
                vm.result = 'Cette adresse email appartient déjà à un compte existant.';
                vm.showResult = true;
                vm.color = 'grey';
            }
        }
    },
};
</script>
